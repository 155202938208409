.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px; // Adjust width as needed
    box-sizing: border-box;
  }
  
  .login-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    box-sizing: border-box;
  }
  
  .login-container input:focus {
    border-color: #007bff; // Or any color you prefer
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  }
  
  .login-container button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }

  .error-message {
    color: red;
    font-size: 0.8rem;
    margin: 5px 0;
  }
  
  .input-error {
    border: 1px solid red;
  }
  
  