.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh; 
}
.avionica-logo{
  margin-top: 17vh;
  width: 40%;
  margin-bottom: 3vh;
}

.card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px; 
  background-color: #fff;
  height: 80vh; 
  margin: 80px; 


  .form-container {
    height: 80vh; 
    width: 100%;

    .form-group {
      margin: 10px;

      .form-labels {
        margin-bottom: 5px;
        font-weight: 300;
        font-size: 1.2rem;
        color: grey;
      }
    }

    .form-check {
      margin: 10px;

      label {
        color: grey;
      }

      .form-check-input {
        box-shadow: none;
        cursor: pointer;
      }

      .form-check-input:checked {
        background-color: #ffc107;
        border-color: #ffc107;
      }
    }
  }

  .progress-bar-container, .spinner-container, .done-container {  
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon-header i {
      font-size: 4em;
    }
  }
}
 
.card-buttons{
  display: flex;
  justify-content: space-evenly;
}

.file-container {
  margin-top:10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;}

.search-file {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
}

.search-file:focus {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
}

.space-files{
  height: 200px;
}

.table-card {
  width: 80%;
  margin-top:30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  border-collapse: collapse;
  display: table; 
  background-color: rgba(248, 248, 248, 0.9);
}

th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid black !important;
  border-right: 1px solid black;
  vertical-align: middle
}

th:last-child, td:last-child {
  border-right: none;
}

th {
  border-top: 1px solid black;
}

tr:last-child td {
  border-bottom: none;
}
td {
  border: 1px solid black;
}


// @media screen and (max-width: 280px) {
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh; 
//   }
  
//   .card {
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     width: 90%;
//     max-width: 600px; 
//     background-color: #fff;
//     height: auto; 
//     margin: 80px; 
  
//     .form-container {
//       width: 100%;
  
//       .form-group {
//         margin: 10px;
  
//         .form-labels {
//           margin-bottom: 5px;
//           font-weight: 300;
//           font-size: 1.2rem;
//           color: grey;
//         }
//       }
  
//       .form-check {
//         margin: 10px;
  
//         label {
//           color: grey;
//         }
  
//         .form-check-input {
//           box-shadow: none;
//           cursor: pointer;
//         }
  
//         .form-check-input:checked {
//           background-color: #ffc107;
//           border-color: #ffc107;
//         }
//       }
//     }
  
//     .progress-bar-container, .spinner-container, .done-container {
//       height: auto; 
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
  
//       .icon-header i {
//         font-size: 4em;
//       }
//     }
//   }

//   .card-buttons{
//     display: flex;
//     justify-content: space-around;
//     flex-direction: column;
//   }
// }

// @media screen and (min-width: 281px) and (max-width: 360px) {
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh; 
//   }
  
//   .card {
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     width: 90%;
//     max-width: 600px; 
//     background-color: #fff;
//     height: auto; 
//     margin: 80px; 
  
//     .form-container {
//       width: 100%;
  
//       .form-group {
//         margin: 10px;
  
//         .form-labels {
//           margin-bottom: 5px;
//           font-weight: 300;
//           font-size: 1.2rem;
//           color: grey;
//         }
//       }
  
//       .form-check {
//         margin: 10px;
  
//         label {
//           color: grey;
//         }
  
//         .form-check-input {
//           box-shadow: none;
//           cursor: pointer;
//         }
  
//         .form-check-input:checked {
//           background-color: #ffc107;
//           border-color: #ffc107;
//         }
//       }
//     }
  
//     .progress-bar-container, .spinner-container, .done-container {
//       height: auto; 
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
  
//       .icon-header i {
//         font-size: 4em;
//       }
//     }
//   }
   
//   .card-buttons{
//     display: flex;
//     justify-content: space-around;
//     flex-direction: column;
//   }
// }

// /* Medium Screen */
// @media screen and (min-width: 361px) and (max-width: 425px) {
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh; 
//   }
  
//   .card {
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     width: 90%;
//     max-width: 600px; 
//     background-color: #fff;
//     height: auto; 
//     margin: 80px; 
  
//     .form-container {
//       width: 100%;
  
//       .form-group {
//         margin: 10px;
  
//         .form-labels {
//           margin-bottom: 5px;
//           font-weight: 300;
//           font-size: 1.2rem;
//           color: grey;
//         }
//       }
  
//       .form-check {
//         margin: 10px;
  
//         label {
//           color: grey;
//         }
  
//         .form-check-input {
//           box-shadow: none;
//           cursor: pointer;
//         }
  
//         .form-check-input:checked {
//           background-color: #ffc107;
//           border-color: #ffc107;
//         }
//       }
//     }
  
//     .progress-bar-container, .spinner-container, .done-container {
//       height: auto; 
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
  
//       .icon-header i {
//         font-size: 4em;
//       }
//     }
//   }
   
//   .card-buttons{
//     display: flex;
//     justify-content: space-around;
//     flex-direction: column;
//   }
// }


// /* Medium-Large Screen */
// @media screen and (min-width: 426px) and (max-width: 682px) {
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh; 
//   }
  
//   .card {
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     width: 90%;
//     max-width: 600px; 
//     background-color: #fff;
//     height: auto; 
//     margin: 80px; 
  
//     .form-container {
//       width: 100%;
  
//       .form-group {
//         margin: 10px;
  
//         .form-labels {
//           margin-bottom: 5px;
//           font-weight: 300;
//           font-size: 1.2rem;
//           color: grey;
//         }
//       }
  
//       .form-check {
//         margin: 10px;
  
//         label {
//           color: grey;
//         }
  
//         .form-check-input {
//           box-shadow: none;
//           cursor: pointer;
//         }
  
//         .form-check-input:checked {
//           background-color: #ffc107;
//           border-color: #ffc107;
//         }
//       }
//     }
  
//     .progress-bar-container, .spinner-container, .done-container {
//       height: auto; 
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
  
//       .icon-header i {
//         font-size: 4em;
//       }
//     }
//   }
   
//   .card-buttons{
//     display: flex;
//     justify-content: space-around;
//   }
// }


// @media screen and (min-width: 683px) and (max-width: 791px) {
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh; 
//   }
  
//   .card {
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     width: 90%;
//     max-width: 600px; 
//     background-color: #fff;
//     height: auto; 
//     margin: 80px; 
  
//     .form-container {
//       width: 100%;
  
//       .form-group {
//         margin: 10px;
  
//         .form-labels {
//           margin-bottom: 5px;
//           font-weight: 300;
//           font-size: 1.2rem;
//           color: grey;
//         }
//       }
  
//       .form-check {
//         margin: 10px;
  
//         label {
//           color: grey;
//         }
  
//         .form-check-input {
//           box-shadow: none;
//           cursor: pointer;
//         }
  
//         .form-check-input:checked {
//           background-color: #ffc107;
//           border-color: #ffc107;
//         }
//       }
//     }
  
//     .progress-bar-container, .spinner-container, .done-container {
//       height: auto; 
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
  
//       .icon-header i {
//         font-size: 4em;
//       }
//     }
//   }
   
//   .card-buttons{
//     display: flex;
//     justify-content: space-around;
//   }
// }


// /* Large Screen */
// @media screen and (min-width: 683px) and (max-width: 1100px) {
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh; 
//   }
  
//   .card {
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     width: 90%;
//     max-width: 600px; 
//     background-color: #fff;
//     height: auto; 
//     margin: 80px; 
  
//     .form-container {
//       width: 100%;
  
//       .form-group {
//         margin: 10px;
  
//         .form-labels {
//           margin-bottom: 5px;
//           font-weight: 300;
//           font-size: 1.2rem;
//           color: grey;
//         }
//       }
  
//       .form-check {
//         margin: 10px;
  
//         label {
//           color: grey;
//         }
  
//         .form-check-input {
//           box-shadow: none;
//           cursor: pointer;
//         }
  
//         .form-check-input:checked {
//           background-color: #ffc107;
//           border-color: #ffc107;
//         }
//       }
//     }
  
//     .progress-bar-container, .spinner-container, .done-container {
//       height: auto; 
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
  
//       .icon-header i {
//         font-size: 4em;
//       }
//     }
//   }
   
//   .card-buttons{
//     display: flex;
//     justify-content: space-around;
//   }
// }