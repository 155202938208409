html, body, #root {
  height: 100vh;
  padding: 0;
  margin: 0;
  border: 0;
}

body {
  background: rgb(40,40,40);
  background: -moz-linear-gradient(90deg, rgba(40,40,40,1) 0%, rgba(255,200,69,1) 27%, rgba(251,211,115,1) 50%, rgba(255,202,75,1) 71%, rgba(40,40,40,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(40,40,40,1) 0%, rgba(255,200,69,1) 27%, rgba(251,211,115,1) 50%, rgba(255,202,75,1) 71%, rgba(40,40,40,1) 100%);
  background: linear-gradient(90deg, rgba(40,40,40,1) 0%, rgba(255,200,69,1) 27%, rgba(251,211,115,1) 50%, rgba(255,202,75,1) 71%, rgba(40,40,40,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#282828",endColorstr="#282828",GradientType=1);
}

